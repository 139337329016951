<script setup lang="ts">
import { computed, ref } from 'vue';

import SpinnerIndicator from '@/components/SpinnerIndicator.vue';
import DeskFlexbox from '@/components/shared/DeskFlexbox/DeskFlexbox.vue';

const props = defineProps<{
  images: string[];
  isLoading: boolean;
}>();

const selectedImageIndex = ref(0);

const hasMultipleImages = computed(() => props.images.length > 1);
</script>

<template>
  <DeskFlexbox direction="column">
    <div v-if="isLoading">
      <SpinnerIndicator />
    </div>
    <div v-else>
      <img
        :src="props.images[selectedImageIndex]"
        class="w-full h-96 object-contain rounded-md mb-2"
      />
      <div
        v-if="hasMultipleImages"
        class="flex overflow-x-auto"
      >
        <img
          v-for="(image, imageIndex) in props.images"
          :key="imageIndex"
          :src="image"
          @click="selectedImageIndex = imageIndex"
          class="h-32 cursor-pointer mr-2 rounded-md"
        />
      </div>
    </div>
  </DeskFlexbox>
</template>

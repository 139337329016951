import { type RemovableRef, useLocalStorage, useSessionStorage } from '@vueuse/core';
import { defineStore } from 'pinia';

import { getItems } from '@/common/api/getItems';
import type { SortByOption } from '@/common/constants/items';
import { formatDate } from '@/common/dateUtils';
import { useCurrenciesStore } from '@/stores/currencies';
import { useItemFiltersStore } from '@/stores/itemFilters';
import { useSearchStore } from '@/stores/search';
import { useTagsStore } from '@/stores/tags';
import type { Item, ItemListPayload, ItemPayload, PagedItemsMap } from '@/types';

interface ItemsStoreState {
  pagedItems: PagedItemsMap;
  nextPageUrl: string | null;
  totalCount: RemovableRef<number>;
  isLoading: boolean;
  filtersEnabled: RemovableRef<boolean>;
}

export async function transformSingleItemData(itemPayload?: ItemPayload): Promise<Item> {
  try {
    if (!itemPayload) {
      throw new Error('Invalid item payload received.');
    }
    const item = {
      itemId: itemPayload.id,
      name: itemPayload.name,
      shortName: itemPayload.short_name,
      headline: itemPayload.headline,
      location: itemPayload.location,
      companyId: itemPayload.company_id,
      tags: itemPayload.tags,
      company: {
        id: itemPayload.company?.id,
        companyUrl: itemPayload.company?.company_url,
        companyImage: itemPayload.company?.image_logo_url,
        name: itemPayload.company?.name,
        shortName: itemPayload.company?.shortname,
        language: itemPayload.company?.language,
        timezone: itemPayload.company?.timezone,
        about: itemPayload.company?.about,
        imageUrl: itemPayload.company?.image_url,
        createdAt: formatDate(itemPayload.company?.created_at),
        modifiedAt: formatDate(itemPayload.company?.modified_at),
        primaryLocation: itemPayload.company?.primary_location,
        currency: itemPayload.company?.currency,
      },
      mainImageUrl: itemPayload.main_image_url,
      createdAt: formatDate(itemPayload.created_at),
      modifiedAt: formatDate(itemPayload.modified_at),
      extraImageCount: Math.max(itemPayload.image_count - 1, 0),
      qualityScore: itemPayload.quality_score,
      companyItemCount: itemPayload.company_item_count,
      description: itemPayload.description,
      ...(itemPayload.structured_description && {
        structuredDescription: {
          id: itemPayload.structured_description?.id,
          duration: itemPayload.structured_description?.duration,
          cancellationSummary: itemPayload.structured_description?.cancellation_summary,
          meetingPoint: itemPayload.structured_description?.meeting_point,
          minAge: itemPayload.structured_description?.min_age,
          maxAge: itemPayload.structured_description?.max_age,
          groupSize: itemPayload.structured_description?.group_size,
          accessibility: itemPayload.structured_description?.accessibility,
          pricing: itemPayload.structured_description?.pricing,
          description: itemPayload.structured_description?.description,
          whatIsIncluded: itemPayload.structured_description?.what_is_included,
          whatIsNotIncluded: itemPayload.structured_description?.what_is_not_included,
          itinerary: itemPayload.structured_description?.itinerary,
          highlights: itemPayload.structured_description?.highlights,
          checkInDetails: itemPayload.structured_description?.check_in_details,
          whatToBring: itemPayload.structured_description?.what_to_bring,
          specialRequirements: itemPayload.structured_description?.special_requirements,
          restrictions: itemPayload.structured_description?.restrictions,
          extras: itemPayload.structured_description?.extras,
          disclaimers: itemPayload.structured_description?.disclaimers,
          faqs: itemPayload.structured_description?.faqs,
        },
      }),
    };

    console.debug('Transformed item:', item);
    return item;
  } catch (error) {
    console.debug('Error transforming single item payload:', error);
    throw error;
  }
}

interface LoadPageItemsOptions {
  page?: number | null;
  pageSize: number | null;
  sortBy?: SortByOption | null;
}

const transformItemsData = async (data: ItemListPayload): Promise<Array<Item>> => {
  console.debug('Received payload for transformation:', data);
  if (!data || !data.results) {
    console.debug('Data or results are undefined:', data);
    throw new Error('Invalid data structure received from API.');
  }

  try {
    const transformedItems = await Promise.all(
      data.results.map(async (itemPayload) => {
        console.debug('Transforming item payload:', itemPayload);
        return transformSingleItemData(itemPayload);
      }),
    );
    console.debug('Successfully transformed items:', transformedItems);
    return transformedItems;
  } catch (error) {
    console.debug('Error during item transformation:', error);
    throw error;
  }
};

export const useItemsStore = defineStore('itemsStore', {
  state: (): ItemsStoreState => ({
    pagedItems: new Map<number, Array<Item>>(),
    nextPageUrl: null,
    totalCount: useLocalStorage('totalCount', 0),
    filtersEnabled: useSessionStorage('inventory/items/filters/enabled', true),
    isLoading: true,
  }),

  getters: {
    nextPageNumber(): number | null {
      if (this.nextPageUrl === null) return null;

      const page = new URL(this.nextPageUrl).searchParams.get('page');

      if (page === null) return null;

      try {
        return Number.parseInt(page, 10);
      } catch (SyntaxError) {
        return null;
      }
    },

    itemsByPage(): (page: number) => Array<Item> {
      return (page: number) => [...(this.pagedItems.get(page) ?? [])];
    },
  },

  actions: {
    async loadPageItems({ page = 1, pageSize = 10, sortBy }: LoadPageItemsOptions) {
      console.debug(`Loading ${pageSize} items for endpoint page: ${page}`);

      const itemFiltersStore = useItemFiltersStore();
      const searchStore = useSearchStore();
      const currenciesStore = useCurrenciesStore();
      const tagsStore = useTagsStore();

      const options = {
        sortBy,
        tags: null, // The tags are not used to update currencies and tags.
        offeredSince: itemFiltersStore.offeredSince,
        currencies: itemFiltersStore.currencies,
        includePhotos: itemFiltersStore.includePhotos,
        searchLocation: searchStore.locationSearch,
        search: searchStore.companyItemSearch,
      };

      currenciesStore.loadAllCurrencies(options);
      tagsStore.loadAllTags(options);

      const fullOptions = {
        ...options,
        page,
        pageSize,
        tags: itemFiltersStore.tags,
      };

      try {
        this.isLoading = true;

        const { status, data } = await getItems(fullOptions);
        this.isLoading = false;

        if (status !== 200 || !data) {
          throw new Error('Failed to load Items');
        }

        const transformedItems = await transformItemsData(data);

        // Update items after data is fetched
        this.pagedItems.set(page ?? 1, transformedItems);

        this.nextPageUrl = data.next;
        this.totalCount = data.count;
      } catch (e: unknown) {
        console.debug(`Unable to fetch items: ${e}`);
      } finally {
        this.isLoading = false;
      }
    },

    clear() {
      this.pagedItems.clear();
    },

    hideFilters() {
      this.filtersEnabled = false;
    },

    showFilters() {
      this.filtersEnabled = true;
    },
  },
});

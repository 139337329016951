<script setup lang="ts">
import { defineProps, ref } from 'vue';

import { FhIcon } from '@fareharbor-com/beacon-vue';
import { chevronDown, chevronUp } from '@fareharbor-com/beacon-vue/icons';

import DeskTitleSubtitle from '@/components/shared/DeskTitleSubtitle/DeskTitleSubtitle.vue';

interface CollapsibleSectionProps {
  headerText?: string;
  isInitiallyExpanded?: boolean;
  large?: boolean;
}

const props = withDefaults(defineProps<CollapsibleSectionProps>(), {
  headerText: '',
  isInitiallyExpanded: false,
  large: false,
});

const emit = defineEmits(['toggle']);

const isExpanded = ref(props.isInitiallyExpanded);

const toggle = () => {
  isExpanded.value = !isExpanded.value;
  emit('toggle', isExpanded.value);
};
</script>
<template>
  <div
    class="section"
    tabindex="0"
    @keyup.enter="toggle"
  >
    <div
      class="flex justify-between py-4 cursor-pointer"
      @click="toggle"
    >
      <div class="heading-left">
        <DeskTitleSubtitle
          v-if="large"
          :title="headerText"
          :subtitle="''"
        />
        <span
          v-else
          v-text="headerText"
        ></span>
      </div>
      <div class="">
        <FhIcon
          size="lg"
          :icon="isExpanded ? chevronUp : chevronDown"
        />
      </div>
    </div>
    <div
      v-if="isExpanded"
      :class="`content ${large ? 'pb-4' : ''}`"
    >
      <slot />
    </div>
  </div>
</template>

<style scoped>
.chevron.expanded {
  transform: scaleY(1);
}

.chevron.collapsed {
  transform: scaleY(-1);
}
</style>

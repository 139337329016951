<script setup lang="ts">
import {
  FhIcon,
  FhLabel, // @ts-ignore
} from '@fareharbor-com/beacon-vue';

interface Props {
  icon: string;
  label: string;
  text: string;
}

const props = withDefaults(defineProps<Props>(), {
  icon: '',
  label: '',
  text: '',
});
</script>

<template>
  <div class="row">
    <FhIcon
      v-if="props.icon"
      :icon="props.icon"
      size="lg"
      class="icon"
    />
    <FhLabel class="info">
      {{ props.label }}
      <template #helpText>
        {{ props.text }}
      </template>
    </FhLabel>
  </div>
</template>

<style scoped>
.row {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 0;
}

.row:not(:last-child) {
  margin-bottom: 18px;
}

.row .icon {
  margin-top: 4px;
  margin-right: 18px;
}

.row .info {
  float: right;
  margin-top: 6px;
}

.row .info:deep(.fh-label-text) {
  font-size: 16px;
}

.row .info:deep(.fh-help-text) {
  font-size: 14px;
}
</style>

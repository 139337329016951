<script setup lang="ts">
import type { BadgeType } from './types';
import { useBadgeClasses } from './useBadgeClasses';

interface DeskBadgeProps {
  type?: BadgeType;
  dataTestId?: string;
}

const props = withDefaults(defineProps<DeskBadgeProps>(), {
  type: 'default',
});
const { badgeClasses } = useBadgeClasses(props);
</script>

<template>
  <span :class="badgeClasses" :data-test-id="dataTestId || 'desk-badge'">
    <slot></slot>
  </span>
</template>

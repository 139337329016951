<script setup lang="ts">
import { FhButton, FhText } from '@fareharbor-com/beacon-vue';
import { close } from '@fareharbor-com/beacon-vue/icons';

import DeskBadge from '@/components/shared/DeskBadge/DeskBadge.vue';
import DeskFlexbox from '@/components/shared/DeskFlexbox/DeskFlexbox.vue';

interface AppliedFilterBadgeProps {
  text: string;
}

defineProps<AppliedFilterBadgeProps>();

const emit = defineEmits(['close']);
</script>
<template>
  <DeskBadge
    class="w-auto pl-2 pr-1"
    data-test-id="applied-filter-badge"
  >
    <DeskFlexbox
      align-items="center"
      gap="1"
    >
      <FhText
        tag="span"
        variant="body-lg"
        weight="medium"
        color="link-default"
      >
        {{ text }}
      </FhText>
      <FhButton
        variant="tertiary"
        size="xs"
        @click="emit('close')"
        :icon="close"
      />
    </DeskFlexbox>
  </DeskBadge>
</template>

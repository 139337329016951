<script setup lang="ts" generic="TColumnKey extends string">
import DeskCheckbox from '@/components/shared/DeskCheckbox/DeskCheckbox.vue';
import DeskTableSortIcon from './DeskTableSortIcon.vue';
import type { ColumnConfig } from './types';

interface Props<TColumnKey extends string> {
  columnDefinitions: ColumnConfig<TColumnKey>[];
  sortingDirection: number;
  sortKey: string;
  isSelectable?: boolean;
  isSelectAllChecked?: boolean;
  selectedIds?: Set<string | number>;
  rowIdentifier?: string;
}

const emits = defineEmits<{
  sort: [columnKey: TColumnKey];
  selectAll: [isChecked: boolean];
}>();

const sortHandler = (columnKey: TColumnKey) => {
  emits('sort', columnKey);
};

const toggleSelectAllRows = (isChecked: boolean) => {
  emits('selectAll', isChecked);
};

defineProps<Props<TColumnKey>>();
</script>

<template>
  <thead
    class="border font-medium bg-gray-50 border-solid border-gray-200 text-gray-500"
    data-test-id="item-table-header-section"
  >
    <tr>
      <th
        v-if="isSelectable && rowIdentifier"
        class="px-2 py-3"
        data-test-id="desk-table-header-checkbox"
      >
        <DeskCheckbox
          :modelValue="isSelectAllChecked"
          @update:modelValue="toggleSelectAllRows"
        />
      </th>
      <th
        v-for="value in columnDefinitions"
        :key="value.key"
        scope="col"
        class="px-2 py-3"
        @click="value.isSortable && sortHandler(value.key)"
      >
        {{ value.header }}
        <DeskTableSortIcon
          v-if="value.isSortable"
          :sortKey="sortKey"
          :columnKey="value.key"
          :sortingDirection="sortingDirection"
        />
      </th>
    </tr>
  </thead>
</template>

<script setup lang="ts">
import { onMounted } from 'vue';

import '@fareharbor-com/beacon-vue/fonts/roboto';
import { dashboardLightTheme, useTheme } from '@fareharbor-com/beacon-vue/themes';
import { initFlowbite } from 'flowbite';

import MainFooter from '@/components/MainFooter.vue';
import MainHeader from '@/components/MainHeader.vue';
import ToastContainer from '@/components/ToastContainer.vue';
import DeskFlexbox from '@/components/shared/DeskFlexbox/DeskFlexbox.vue';

useTheme(dashboardLightTheme);

onMounted(() => {
  initFlowbite();
});
function throwError() {
  throw new Error('Sentry Error');
}
</script>

<template>
  <DeskFlexbox class="min-h-screen flex flex-col">
    <MainHeader />
    <RouterView :key="$route.fullPath" />
    <MainFooter />
    <button
      class="hidden"
      @click="throwError"
    >
      Throw error
    </button>
  </DeskFlexbox>
  <ToastContainer />
</template>

<style scoped></style>

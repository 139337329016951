<script setup lang="ts">
import { computed, defineProps } from 'vue';

import { FhButton, FhIcon, FhLink, FhText } from '@fareharbor-com/beacon-vue';
import { location as locationIcon } from '@fareharbor-com/beacon-vue/icons';

import InfoCopy from '@/components/InfoCopy.vue';
import DeskFlexbox from '@/components/shared/DeskFlexbox/DeskFlexbox.vue';
import DeskLabel from '@/components/shared/DeskLabel/DeskLabel.vue';

interface LocationProps {
  city?: string;
  province?: string;
  country?: string;
}

interface ItemSupplierProps {
  name: string;
  shortname: string;
  companyId: string;
  companyUrl: string | null;
  companyImage: string | null;
  location?: LocationProps;
  supplierItemCount: number;
  itemId: number;
  inFhdnApi?: boolean;
  inFhdn?: boolean;
  companyCurrency: string;
  companyShortName?: string;
  isStaff?: boolean;
}
const props = defineProps<ItemSupplierProps>();

const supplierItemCountText = computed(() => {
  if (props.supplierItemCount === 1) {
    return `${props.supplierItemCount} item`;
  }
  return `${props.supplierItemCount} items`;
});

const itemLink = computed(
  () =>
    `https://fareharbor.com/api/external/v1/companies/${props.shortname}/items/${props.itemId}/`,
);

const handleCopyLinkClick = (event: MouseEvent) => {
  event.preventDefault();
  event.stopPropagation();
  const asn =
    props.companyCurrency.toLowerCase() === 'usd' ? 'fhdn' : `fhdn-${props.companyCurrency}`;
  const referralLink = props.isStaff
    ? `https://fareharbor.com/embeds/book/${props.shortname}/items/${props.itemId}/`
    : `https://fareharbor.com/embeds/book/${props.shortname}/items/${props.itemId}/?asn=${asn}&asn-ref=${props.companyShortName}`;

  navigator.clipboard
    .writeText(referralLink)
    .then(() => {
      console.log('Link copied to clipboard:', referralLink);
    })
    .catch((error) => {
      console.error('Failed to copy link:', error);
    });
};
</script>

<template>
  <FhText
    tag="h1"
    weight="medium"
    size="display-xl"
  >
    About the supplier
  </FhText>
  <img
    v-if="companyImage"
    :src="companyImage"
    :alt="name"
    class="w-40 rounded-lg"
  />
  <DeskFlexbox
    gap="4"
    direction="column"
  >
    <DeskFlexbox direction="column">
      <FhText
        tag="h1"
        weight="medium"
        size="display-xl"
      >
        {{ name }}
      </FhText>
      <FhLink
        v-if="companyUrl"
        variant="primary"
        :href="companyUrl"
        target="_blank"
      >
        {{ companyUrl }}
      </FhLink>
      <DeskFlexbox
        v-if="location?.city"
        alignItems="center"
      >
        <FhIcon
          :icon="locationIcon"
          size="lg"
        />
        <DeskLabel
          class="font-normal py-4 px-2"
          :label="location?.city || ''"
          :description="
            [location?.province || '', location?.country || ''].filter(Boolean).join(', ')
          "
        />
      </DeskFlexbox>
    </DeskFlexbox>

    <FhLink
      variant="primary"
      :href="`/?search=${shortname}&includePhotos=false`"
      target="_blank"
    >
      <FhText
        tag="h1"
        weight="medium"
        size="display-sm"
        color="link-default"
      >
        More items by this supplier ({{ supplierItemCountText }})
      </FhText>
    </FhLink>
  </DeskFlexbox>

  <FhText
    tag="h1"
    weight="medium"
    size="display-xl"
    class="title-text"
  >
    Developers
  </FhText>
  <DeskFlexbox
    gap="2"
    direction="column"
  >
    <div class="w-auto">
      <FhLink
        variant="primary"
        target="_blank"
        href="https://developer.fareharbor.com/api/external/v1/#tag/Items-Endpoints"
      >
        API Reference
      </FhLink>
    </div>
    <div
      v-if="inFhdnApi"
      class="rounded-lg border-solid border-2 bg-gray-100 px-4 py-2 relative"
    >
      <InfoCopy
        :information="itemLink"
        class="absolute right-5 top-2"
      />
      <DeskFlexbox direction="column">
        <FhText
          tag="span"
          variant="body-md"
          weight="medium"
        >
          API
        </FhText>
        <FhText
          tag="span"
          variant="body-md"
          color="subtle"
        >
          {{ itemLink }}
        </FhText>
      </DeskFlexbox>
    </div>
    <div class="rounded-lg border-solid border-2 bg-gray-100 px-4 py-2 relative">
      <InfoCopy
        :information="itemId.toString()"
        class="absolute right-5 top-2"
      />
      <DeskFlexbox direction="column">
        <FhText
          tag="span"
          variant="body-md"
          weight="medium"
          color="subtle"
        >
          Item ID
        </FhText>
        <FhText
          tag="span"
          variant="body-md"
        >
          {{ itemId }}
        </FhText>
      </DeskFlexbox>
    </div>
    <div class="rounded-lg border-solid border-2 bg-gray-100 px-4 py-2 relative">
      <InfoCopy
        :information="shortname"
        class="absolute right-5 top-2"
      />
      <DeskFlexbox direction="column">
        <FhText
          tag="span"
          variant="body-md"
          weight="medium"
          color="subtle"
        >
          Shortname
        </FhText>
        <FhText
          tag="span"
          variant="body-md"
        >
          {{ shortname }}
        </FhText>
      </DeskFlexbox>
    </div>
    <DeskFlexbox
      direction="column"
      v-if="inFhdn"
      class="w-2/3"
    >
      <FhText
        tag="h1"
        weight="medium"
        size="display-xl"
        class="title-text"
      >
        Affiliates
      </FhText>
      <FhButton
        variant="secondary"
        size="lg"
        @click="handleCopyLinkClick"
      >
        Copy referral link
      </FhButton>
    </DeskFlexbox>
  </DeskFlexbox>
</template>
<style>
.title-text {
  padding-top: var(--fh-space-4xl);
  padding-bottom: var(--fh-space-sm);
}
</style>

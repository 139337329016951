<script setup lang="ts">
import { computed, nextTick, onMounted, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import { FhSpinner } from '@fareharbor-com/beacon-vue';
import { initFlowbite, initTooltips } from 'flowbite';

import { getItem } from '@/common/api/getItem';
import { getItemImages } from '@/common/api/getItemImages';
import { NOT_FOUND } from '@/common/constants/status';
import { markdownToHtml } from '@/common/markdownToHtml';
import ImageGallery from '@/components/itemDetail/ImageGallery.vue';
import ItemSupplier from '@/components/itemDetail/ItemSupplier.vue';
import StructuredDescription from '@/components/itemDetail/StructuredDescription.vue';
import DeskFlexbox from '@/components/shared/DeskFlexbox/DeskFlexbox.vue';
import { transformSingleItemData } from '@/stores/items';
import { useUserStore } from '@/stores/user';
import type { Item } from '@/types';

const route = useRoute();
const router = useRouter();
const userStore = useUserStore();

const isItemLoading = ref(false);
const isImagesLoading = ref(true);
const errorLoadingItem = ref(true);

const currentItem = ref<Item | null>(null);
const currentItemImages = ref<string[]>([]);

const isLoading = computed(() => isItemLoading.value || isImagesLoading.value);

const fetchItemDetail = async (itemPk: number) => {
  try {
    const { data, status, error } = await getItem(itemPk);
    if (error) {
      if (status === NOT_FOUND) {
        router.push({ name: 'notFound' });
        return;
      }
      console.error(`Unable to fetch item: ${error.message}`);
      errorLoadingItem.value = true;
      return;
    }

    const transformedItem = await transformSingleItemData(data);
    currentItem.value = transformedItem;

    await nextTick();
    initTooltips();
  } catch (e: unknown) {
    console.error(`An unexpected error occurred while fetching item: ${e}`);
    errorLoadingItem.value = true;
  } finally {
    isItemLoading.value = false;
  }
};

const fetchItemImages = async (itemPk: number) => {
  isImagesLoading.value = true;
  try {
    const { data: imageData = [], error: imageError } = await getItemImages(itemPk);
    if (imageError) {
      console.error(`Unable to fetch item images: ${imageError.message}`);
    }
    currentItemImages.value = imageData.map((image) => image.image_url);
  } catch (e: unknown) {
    console.error(`An unexpected error occurred while fetching images: ${e}`);
  } finally {
    isImagesLoading.value = false;
  }
};

const loadItem = () => {
  const { itemPk } = route.params;
  if (itemPk) {
    const itemPkNumber = Number(itemPk);
    fetchItemDetail(itemPkNumber);
    fetchItemImages(itemPkNumber);
  }
};

onMounted(async () => {
  await userStore.fetchUserData();
  loadItem();
  initFlowbite();
});

watch(
  () => route.params.itemPk,
  (newItemPk) => {
    if (newItemPk) {
      const itemPkNumber = Number(newItemPk);
      fetchItemDetail(itemPkNumber);
      fetchItemImages(itemPkNumber);
    }
  },
);
</script>

<template>
  <main class="container mx-auto px-4 pt-7 pb-36 flex-1 content-center">
    <div
      v-if="isLoading"
      class="flex items-center justify-center"
    >
      <FhSpinner size="lg" />
    </div>

    <DeskFlexbox
      v-else-if="currentItem && !isLoading"
      class="min-h-screen"
      direction="column"
    >
      <span class="text-xs font-normal text-gray-500">
        {{ currentItem.tags.map((tag) => tag.name.toUpperCase()).join(', ') }}
      </span>

      <span class="text-3xl font-medium mt-1">
        {{ currentItem.name }}
      </span>

      <span class="text-lg font-medium text-gray-400">
        {{ currentItem.headline }}
      </span>

      <div class="grid grid-cols-2 gap-12 mt-6">
        <DeskFlexbox
          direction="column"
          grow="1"
          gap="4"
        >
          <ImageGallery
            :images="currentItemImages"
            :isLoading="isImagesLoading"
          />

          <StructuredDescription
            v-if="currentItem.structuredDescription"
            :itemDescription="currentItem.structuredDescription"
            :item="currentItem"
          />
          <DeskFlexbox
            v-else
            class="mt-3 mb-6"
          >
            <div
              class="prose"
              v-html="markdownToHtml(currentItem.description || '')"
            />
          </DeskFlexbox>
        </DeskFlexbox>
        <DeskFlexbox
          direction="column"
          grow="1"
        >
          <ItemSupplier
            :name="currentItem.company.name"
            :shortname="currentItem.company.shortName"
            :companyId="currentItem.company.id.toString()"
            :companyUrl="currentItem.company.companyUrl"
            :location="currentItem.company.primaryLocation"
            :supplierItemCount="currentItem.companyItemCount"
            :itemId="currentItem.itemId"
            :companyImage="currentItem.company.companyImage"
            :inFhdnApi="userStore.user?.inFhdnApi"
            :inFhdn="userStore.user?.inFhdn"
            :isStaff="userStore.user?.user.isStaff"
            :companyCurrency="currentItem.company.currency"
            :companyShortName="userStore.user?.company?.shortName"
          />
        </DeskFlexbox>
      </div>
    </DeskFlexbox>

    <div v-else-if="!isLoading && !errorLoadingItem">
      <p>Error loading item details.</p>
    </div>
  </main>
</template>

<script setup lang="ts" generic="TColumnKey extends string">
import { computed, onMounted } from 'vue';
import DeskTableBody from './DeskTableBody.vue';
import DeskTableHead from './DeskTableHead.vue';
import type { ColumnConfig, TableData } from './types';
import { useSelectableRows } from './useSelectableRows';
import useSortableColumns from './useSortableColumns';

interface TableProps<TColumnKey extends string> {
  columnDefinitions: ColumnConfig<TColumnKey>[];
  data: TableData;
  noRowsMessage?: string;
  rowIdentifier?: string;
  isSelectable?: boolean;
  selectedIds?: Set<string | number>;
  dataTestId?: string;
  isLoading?: boolean;
}

const props = defineProps<TableProps<TColumnKey>>();
const emit = defineEmits(['select']);

const CHECKBOX_WIDTH_PERCENT = 5;

onMounted(() => {
  if (props.isSelectable && !props.rowIdentifier) {
    throw new Error(
      'rowIdentifier prop is missing on a table with selectable rows',
    );
  }
});

const visibleColumns = props.columnDefinitions.filter(
  (column) => !column.isHidden,
);

const columnWidth = (
  props.isSelectable
    ? (100 - CHECKBOX_WIDTH_PERCENT) / visibleColumns.length
    : 100 / visibleColumns.length
).toFixed(2);
const { sortByColumn, sortKey, sortDirection } = useSortableColumns(props.data);
const emitSelectedRows = (value: Set<string | number>) => {
  emit('select', value);
};
const {
  selectedRows,
  isSelectAllChecked,
  handleSelectAllRows,
  handleRowSelection,
} = useSelectableRows(props.selectedIds, emitSelectedRows);
const isSelectAllEnabled = computed(() => isSelectAllChecked(props.data));
</script>

<template>
  <div class="flex flex-col">
    <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="inline-block min-w-full py-2 sm:px-6 lg:px-8">
        <div class="overflow-hidden">
          <table
            class="min-w-full text-left text-sm font-light"
            :data-test-id="dataTestId || 'desk-table'"
          >
            <colgroup>
              <col
                v-if="isSelectable"
                key="checkboxColumn"
                :style="`width:${CHECKBOX_WIDTH_PERCENT}%`"
              />
              <col
                v-for="(_, index) in visibleColumns"
                :key="`col-${index}`"
                :style="`width:${columnWidth}%`"
              />
            </colgroup>
            <DeskTableHead
              :column-definitions="visibleColumns"
              :sorting-direction="sortDirection"
              :sort-key="sortKey"
              :isSelectable="isSelectable"
              :isSelectAllChecked="isSelectAllEnabled"
              :rowIdentifier="rowIdentifier"
              @sort="sortByColumn"
              @selectAll="
                (isChecked: boolean) =>
                  handleSelectAllRows(isChecked, data, rowIdentifier)
              "
            />
            <DeskTableBody
              :data="data"
              :column-definitions="visibleColumns"
              :no-rows-message="noRowsMessage"
              :isSelectable="isSelectable"
              :selectedIds="selectedRows"
              :rowIdentifier="rowIdentifier"
              :isLoading="isLoading"
              @select="handleRowSelection"
            />
          </table>
          <slot name="footer"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

import { useAttrs, type Ref, computed } from 'vue';
import clsx from 'clsx';
import type { BadgeType } from './types';

const defaultBadgeClasses =
  'mr-2 px-2.5 py-0.5 rounded flex items-center justify-center text-xs font-medium w-fit';

const badgeTextClasses: Record<BadgeType, string> = {
  default: 'text-blue-800',
  dark: 'text-gray-800',
  red: 'text-red-800',
  green: 'text-green-800',
  yellow: 'text-yellow-800',
  indigo: 'text-indigo-800',
  purple: 'text-purple-800',
  pink: 'text-pink-800',
  gray: 'text-gray-800',
};

const badgeTypeClasses: Record<BadgeType, string> = {
  default: 'bg-blue-100',
  dark: 'bg-gray-100',
  red: 'bg-red-100',
  green: 'bg-green-100',
  yellow: 'bg-yellow-100',
  indigo: 'bg-indigo-100',
  purple: 'bg-purple-100',
  pink: 'bg-pink-100',
  gray: 'bg-gray-100',
};

export type UseBadgeClassesProps = {
  type: BadgeType;
};

export function useBadgeClasses(props: UseBadgeClassesProps): {
  badgeClasses: Ref<string>;
} {
  const attrs = useAttrs();
  const badgeClasses = computed<string>(() =>
    clsx(
      defaultBadgeClasses,
      badgeTypeClasses[props.type],
      badgeTextClasses[props.type],
      attrs.class as string,
    ),
  );
  return {
    badgeClasses,
  };
}

<script setup lang="ts">
import { computed, ref, watchEffect } from 'vue';

import {
  accessible as accessibilityIcon,
  age as ageIcon,
  cancellationSummary as cancellationIcon,
  clock as clockIcon,
  group as groupIcon,
  location as locationIcon, // @ts-ignore
} from '@fareharbor-com/beacon-vue/icons';

import { markdownToHtml } from '@/common/markdownToHtml';
import CollapsibleSection from '@/components/CollapsibleSection.vue';
import ItemDescriptionMarkdownView from '@/components/itemDetail/ItemDescriptionMarkdownView.vue';
import OverviewRow from '@/components/itemDetail/OverviewRow.vue';
import DeskFlexbox from '@/components/shared/DeskFlexbox/DeskFlexbox.vue';
import DeskLabel from '@/components/shared/DeskLabel/DeskLabel.vue';
import type { Item, StructuredDescription } from '@/types';

interface Props {
  itemDescription: StructuredDescription;
  item: Item | null;
}

const props = withDefaults(defineProps<Props>(), {
  item: null,
});

const data = ref<any>();

const transformInput = (input: StructuredDescription): any => {
  const overview =
    input.duration ||
    input.cancellationSummary ||
    input.meetingPoint ||
    input.minAge ||
    input.maxAge ||
    input.groupSize ||
    input.accessibility
      ? {
          duration: input.duration || null,
          cancellationSummary: input.cancellationSummary || null,
          meetingPoint: input.meetingPoint || null,
          minAge: input.minAge || null,
          maxAge: input.maxAge || null,
          groupSize: input.groupSize || null,
          accessibility: input.accessibility || null,
        }
      : null;

  const pricing = input.pricing ? markdownToHtml(input.pricing) : null;

  const activityDetails =
    input.description ||
    input.whatIsIncluded ||
    input.whatIsNotIncluded ||
    input.itinerary ||
    input.highlights
      ? {
          description: input.description ? markdownToHtml(input.description) : null,
          whatIsIncluded: input.whatIsIncluded ? markdownToHtml(input.whatIsIncluded) : null,
          whatIsNotIncluded: input.whatIsNotIncluded
            ? markdownToHtml(input.whatIsNotIncluded)
            : null,
          itinerary: input.itinerary ? markdownToHtml(input.itinerary) : null,
          highlights: input.highlights ? markdownToHtml(input.highlights) : null,
        }
      : null;

  const additionalInformation =
    input.checkInDetails ||
    input.whatToBring ||
    input.specialRequirements ||
    input.restrictions ||
    input.extras ||
    input.disclaimers
      ? {
          checkInDetails: input.checkInDetails ? markdownToHtml(input.checkInDetails) : null,
          whatToBring: input.whatToBring ? markdownToHtml(input.whatToBring) : null,
          specialRequirements: input.specialRequirements
            ? markdownToHtml(input.specialRequirements)
            : null,
          restrictions: input.restrictions ? markdownToHtml(input.restrictions) : null,
          extras: input.extras ? markdownToHtml(input.extras) : null,
          disclaimers: input.disclaimers ? markdownToHtml(input.disclaimers) : null,
        }
      : null;

  const faqs = input.faqs ? markdownToHtml(input.faqs) : null;

  return {
    overview,
    pricing,
    activityDetails,
    additionalInformation,
    faqs,
  };
};

watchEffect(async () => {
  const result = props.itemDescription;
  data.value = transformInput(result);
});

const age = computed(() => {
  if (data.value === null) {
    return null;
  }
  const { minAge, maxAge } = data.value.overview;
  if (!minAge && !maxAge) {
    return null;
  }
  if (minAge && !maxAge) {
    return `${minAge}+`;
  }
  if (maxAge && !minAge) {
    return `${maxAge} 'and under'`;
  }
  return `${minAge} \u2013 ${maxAge}`;
});
</script>

<template>
  <DeskFlexbox
    v-if="data"
    direction="column"
    class="structured-description divide-y"
  >
    <CollapsibleSection
      v-if="data.overview"
      headerText="Overview"
      :isInitiallyExpanded="true"
      :large="true"
    >
      <OverviewRow
        v-if="data.overview.duration"
        :icon="clockIcon"
        label="Duration"
        :text="data.overview.duration"
      />
      <OverviewRow
        v-if="data.overview.meetingPoint"
        :icon="locationIcon"
        label="Meeting point"
        :text="data.overview.meetingPoint"
      />
      <OverviewRow
        v-if="data.overview.cancellationSummary"
        :icon="cancellationIcon"
        label="Cancellations"
        :text="data.overview.cancellationSummary"
      />
      <OverviewRow
        v-if="age"
        :icon="ageIcon"
        label="Age"
        :text="age"
      />
      <OverviewRow
        v-if="data.overview.groupSize"
        :icon="groupIcon"
        label="Group size"
        :text="data.overview.groupSize"
      />
      <OverviewRow
        v-if="data.overview.accessibility"
        :icon="accessibilityIcon"
        label="Accessibility"
        :text="data.overview.accessibility"
      />
    </CollapsibleSection>
    <CollapsibleSection
      v-if="data.activityDetails"
      headerText="Activity details"
      :isInitiallyExpanded="false"
      :large="true"
    >
      <div
        v-if="data.activityDetails.description"
        class="subsection"
      >
        <ItemDescriptionMarkdownView :html="data.activityDetails.description" />
      </div>
      <div
        v-if="data.activityDetails.whatIsIncluded"
        class="subsection"
      >
        <DeskLabel
          class="font-bold"
          label="What's included"
        />
        <ItemDescriptionMarkdownView :html="data.activityDetails.whatIsIncluded" />
      </div>
      <div
        v-if="data.activityDetails.whatIsNotIncluded"
        class="subsection"
      >
        <DeskLabel
          class="font-bold"
          label="What's not included"
        />
        <ItemDescriptionMarkdownView :html="data.activityDetails.whatIsNotIncluded" />
      </div>
      <div
        v-if="data.activityDetails.itinerary"
        class="subsection"
      >
        <DeskLabel
          class="font-bold"
          label="Itinerary"
        />
        <ItemDescriptionMarkdownView :html="data.activityDetails.itinerary" />
      </div>
      <div
        v-if="data.activityDetails.highlights"
        class="subsection"
      >
        <DeskLabel
          class="font-bold"
          label="Highlights"
        />
        <ItemDescriptionMarkdownView :html="data.activityDetails.highlights" />
      </div>
    </CollapsibleSection>
    <CollapsibleSection
      v-if="data.pricing"
      headerText="Pricing"
      :isInitiallyExpanded="false"
      :large="true"
    >
      <ItemDescriptionMarkdownView :html="data.pricing" />
    </CollapsibleSection>
    <CollapsibleSection
      v-if="data.additionalInformation"
      headerText="Additional information"
      :isInitiallyExpanded="false"
      :large="true"
    >
      <div
        v-if="data.additionalInformation.checkInDetails"
        class="subsection"
      >
        <DeskLabel
          class="font-bold"
          label="Check-in details"
        />
        <ItemDescriptionMarkdownView :html="data.additionalInformation.checkInDetails" />
      </div>
      <div
        v-if="data.additionalInformation.whatToBring"
        class="subsection"
      >
        <DeskLabel
          class="font-bold"
          label="What to bring"
        />
        <ItemDescriptionMarkdownView :html="data.additionalInformation.whatToBring" />
      </div>
      <div
        v-if="data.additionalInformation.specialRequirements"
        class="subsection"
      >
        <DeskLabel
          class="font-bold"
          label="Special requirements"
        />
        <ItemDescriptionMarkdownView :html="data.additionalInformation.specialRequirements" />
      </div>
      <div
        v-if="data.additionalInformation.restrictions"
        class="subsection"
      >
        <DeskLabel
          class="font-bold"
          label="Restrictions"
        />
        <ItemDescriptionMarkdownView :html="data.additionalInformation.restrictions" />
      </div>
      <div
        v-if="data.additionalInformation.extras"
        class="subsection"
      >
        <DeskLabel
          class="font-bold"
          label="Extras"
        />
        <ItemDescriptionMarkdownView :html="data.additionalInformation.extras" />
      </div>
      <div
        v-if="data.additionalInformation.disclaimers"
        class="subsection"
      >
        <DeskLabel
          class="font-bold"
          label="Disclaimers"
        />
        <ItemDescriptionMarkdownView :html="data.additionalInformation.disclaimers" />
      </div>
    </CollapsibleSection>
    <CollapsibleSection
      v-if="data.faqs"
      headerText="FAQs"
      :isInitiallyExpanded="false"
      :large="true"
    >
      <ItemDescriptionMarkdownView :html="data.faqs" />
    </CollapsibleSection>
    <!-- TODO Check if company cancellationNotes are being parsed -->
    <!-- <CollapsibleSection
      v-if="item?.cancellationNotes || item?.company?.cancellationNotes"
      headerText="Cancellations"
      :isInitiallyExpanded="false"
      :large="true"
    >
      <ItemDescriptionMarkdownView
        :html="
          markdownToHtml(
            item?.cancellationNotes || item?.company?.cancellationNotes,
          )
        "
      />
    </CollapsibleSection> -->
  </DeskFlexbox>
</template>

<style scoped>
.subsection:not(:last-child) {
  margin-bottom: 24px;
}

.subsection h2 {
  font-size: 18px;
}
</style>

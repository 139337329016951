<script setup lang="ts">
import { computed, ref, watch } from 'vue';

import { FwbPagination } from 'flowbite-vue';

import DeskFlexbox from '@/components/shared/DeskFlexbox/DeskFlexbox.vue';
import DeskSelect from '@/components/shared/DeskSelect/DeskSelect.vue';
import type { OptionsType } from '@/components/shared/DeskSelect/types';

interface ItemListPaginationProps {
  // items indication
  pageStart: number;
  pageEnd: number;
  totalItemsCount: number;
  showCount: boolean;

  // page navigation
  showPagination: boolean;
  totalPagesCount: number;
  currentPage: number;

  // page size
  currentPageSize: number | string;
  pageSizeOptions: OptionsType[];
}

const props = defineProps<ItemListPaginationProps>();
const emit = defineEmits<{
  pageChanged: [pageNumber: number];
  pageSizeChanged: [pageSize: number];
}>();

const showItemsCount = computed(() => props.showCount && props.totalItemsCount > 0);

const showPaginationComponent = computed(() => props.showPagination && props.totalPagesCount > 1);

const selectedPageSize = ref(props.currentPageSize);

watch(
  () => props.currentPageSize,
  (newVal) => {
    selectedPageSize.value = newVal;
  },
);
</script>

<template>
  <DeskFlexbox
    justifyContent="space-between"
    alignItems="center"
    class="w-full"
  >
    <!-- Left-aligned item count -->
    <DeskFlexbox
      alignItems="center"
      class="mx-2"
    >
      <div
        v-show="showItemsCount"
        class="flex-grow-0"
        data-test-id="item-count-info-footer"
      >
        Page
        <span class="font-bold">{{ pageStart }}-{{ pageEnd }}</span>
        of
        <span class="font-bold">{{ totalItemsCount }}</span>
      </div>

      <DeskFlexbox
        justifyContent="space-between"
        alignItems="center"
        class="mx-2"
      >
        Showing
        <DeskSelect
          v-model="selectedPageSize"
          :options="props.pageSizeOptions"
          @update:model-value="(value) => emit('pageSizeChanged', value)"
          dataTestId="current-page-size"
          class="px-2"
        />
        items
      </DeskFlexbox>
    </DeskFlexbox>

    <!-- Centered pagination -->
    <div class="flex justify-end flex-grow">
      <FwbPagination
        v-show="showPaginationComponent"
        show-icons
        :show-labels="false"
        :total-pages="totalPagesCount"
        :modelValue="currentPage"
        @pageChanged="emit('pageChanged', $event)"
        data-test-id="pagination-section"
      />
    </div>
  </DeskFlexbox>
</template>

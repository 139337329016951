import { API_URLS } from '@/configs';
import type { ItemPayload } from '@/types';

import { type GenericResponse, fetchData } from '.';

type GetItemResponse = GenericResponse<ItemPayload>;

export const getItem = async (itemPk: number): Promise<GetItemResponse> => {
  const url = new URL(`${API_URLS.BASE}/item/${itemPk}`);
  const getItemResponse = await fetchData(url);
  return getItemResponse;
};

<script setup lang="ts">
const props = defineProps({
  html: {
    type: String,
    required: true,
  },
});
</script>

<template>
  <div
    class="content-builder-markdown-view"
    v-html="props.html"
  ></div>
</template>

<style scoped lang="scss">
.content-builder-markdown-view {
  font-size: 16px;
  line-height: 1.35;
  color: var(--fh-color-text-default);

  :deep(h1) {
    font-weight: bold;
    line-height: 2;
    // Overriding .prose styles
    font-size: 16px;
  }

  :deep(h2) {
    font-weight: bold;
    line-height: 1.5;
    // Overriding .prose styles
    font-size: 16px;
  }

  :deep(h3) {
    font-weight: bold;
    line-height: 1.5;
    // Overriding .prose styles
    font-size: 16px;
  }

  :deep(li:first-child) {
    line-height: 1.5;
  }

  :deep(hr) {
    background-color: var(--fh-color-border-subtle);
  }

  :deep(blockquote) {
    border-left: none;
    border-radius: 6px;
  }
}
</style>
